<template>
	<SmFilters
		@onSubmit="applyFilters"
		@onInfoClick="handleOpenInfoAlert"
		@close="$emit('close')"
	>
		<template #countries>
			<CountriesMultiselect
				:all-africa="true"
				:query-params="countryQueryParams"
				v-model="filters.countries"
			/>
		</template>
		<template #indicators>
			<ul class="ul">
				<li
					v-for="indicator in indicators"
					:key="indicator.id"
					:class="{ active: filters.indicator === indicator.slug }"
					@click="filters.indicator = indicator.slug"
				>
					<span>
						<img src="@/assets/imgs/icons/icon-arrow-point.svg" alt="" />
					</span>
					<VLink size="small" :underline="true" @click.prevent>
						{{ indicator.name }}
					</VLink>
				</li>
			</ul>
		</template>
	</SmFilters>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import tooltipAlert from '@/mixins/tooltip-alert';

export default {
	name: 'PAIFilterModal',
	mixins: [tooltipAlert],
	data() {
		return {
			filters: {
				countries: [],
				indicator: ''
			}
		};
	},
	created() {
		this.setFilters();
	},
	computed: {
		...mapGetters(['paiFilters']),
		...mapState({
			countries: state => state.app.countries,
			indicators: state => state.pai.indicators
		}),
		countryQueryParams() {
			return {
				is_active: 'true',
				graph: 'pai',
				indicator: this.paiFilters.indicator
			};
		}
	},
	methods: {
		setFilters() {
			this.filters.countries = this.paiFilters.countries;
			this.filters.indicator = this.paiFilters.indicator;
		},
		applyFilters() {
			this.paiFilters.countries = this.filters.countries;
			this.paiFilters.indicator = this.filters.indicator;

			this.$emit('close');
		},
		handleOpenInfoAlert(title, indicator) {
			indicator = indicator.includes('indicator')
				? `pai-${indicator}`
				: indicator;

			this.openInfoAlert(title, indicator);
		}
	}
};
</script>
